import { FC, ReactNode, useCallback } from "react";
import { NFTList } from "components/NFTList";
import { useWLProject } from "modules/WLProject";
import cx from "classnames";

type Props = {
  projectName: string;
  projectSlug: string;
  children?: ReactNode;
  nfts?: any[];
  isLoading?: boolean;
  error?: any;
  publicKey?: any;
};

const List: FC<Props> = (props) => {
  const { projectName, nfts, isLoading, error, publicKey } = props;

  const isEmpty = !nfts?.length;
  const isErrored = !isLoading && !!error;
  const loading = isEmpty && isLoading;

  const renderZeroResults = () => (
    <div className="mx-auto h-full max-w-6xl flex-1">
      <div className="no-items flex h-full items-center justify-center">
        <div>You don&apos;t have any {projectName} NFTs in here</div>
      </div>
    </div>
  );

  const renderError = () => (
    <div className="flex h-full flex-1 items-center justify-center">
      An error occured :( Try again later
    </div>
  );

  const renderLoading = () => (
    <div className="flex h-full flex-1 items-center justify-center">
      Loading...
    </div>
  );

  const renderWalletConnect = () => (
    <div className="flex h-full flex-1 items-center justify-center">
      Connect your wallet
    </div>
  );

  if (!publicKey) {
    return renderWalletConnect();
  }

  if (isErrored) {
    return renderError();
  }

  return (
    <>
      {loading && renderLoading()}
      {!isEmpty && <NFTList nfts={nfts} />}
      {isEmpty && !loading && renderZeroResults()}
    </>
  );
};

const WalletProjectPage: FC<Props> = (props) => {
  const { walletNFTs, isLoadingWallet, errorWallet, publicKey } =
    useWLProject();

  return (
    <List
      {...props}
      nfts={walletNFTs}
      isLoading={isLoadingWallet}
      error={errorWallet}
      publicKey={publicKey}
    />
  );
};

const StakedProjectPage: FC<Props> = (props) => {
  const { stackedNFTs, isLoadingStaked, errorStaked, publicKey } =
    useWLProject();

  return (
    <List
      {...props}
      nfts={stackedNFTs}
      isLoading={isLoadingStaked}
      error={errorStaked}
      publicKey={publicKey}
    />
  );
};

export { WalletProjectPage, StakedProjectPage };

import { FC, useCallback } from "react";
import { NFTCard } from "components/NFTCard";
import { AnimatePresence } from "framer-motion";
import { CardNFT } from "types";
import { LoadingAction, useNFTCard } from "modules/useNFTCard";

type Props = {
  nfts: CardNFT[];
};

const NFTList: FC<Props> = ({ nfts }) => {
  const {
    stake,
    unstake,
    claim,
    loadingItems,
    fetchStakedInfo,
    stakedInfoByMint,
  } = useNFTCard();

  const getIsLoading = useCallback(
    (mint: string, action: LoadingAction) => {
      if (!loadingItems[mint]) {
        return false;
      }

      return loadingItems[mint][action];
    },
    [loadingItems]
  );

  return (
    <div className="grid flex-1 grid-cols-1 gap-4 gap-y-8 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 xl:grid-cols-6">
      <AnimatePresence>
        {nfts
          .sort((a, b) => b.rarityMultiplier - a.rarityMultiplier)
          .map((nft) => (
            <NFTCard
              key={nft.mint}
              nft={nft}
              stake={stake}
              unstake={unstake}
              claim={claim}
              fetchStakedInfo={fetchStakedInfo}
              stakedInfo={stakedInfoByMint[nft.mint]}
              getIsLoading={getIsLoading}
            />
          ))}
      </AnimatePresence>
    </div>
  );
};

export default NFTList;

import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";

import { useWLProject } from "modules/WLProject";
import { TimeoutToast } from "utils/TimeoutToast";
import { parseSendTransactionError } from "utils/parseSendTransactionError";

export enum LoadingAction {
  STAKE = "STAKE",
  UNSTAKE = "UNSTAKE",
  CLAIM = "CLAIM",
}

type LoadingItem = {
  [LoadingAction.STAKE]?: boolean;
  [LoadingAction.UNSTAKE]?: boolean;
  [LoadingAction.CLAIM]?: boolean;
};

type LoadingItems = {
  [key: string]: LoadingItem;
};

const useNFTCard = () => {
  const [stakedInfoByMint, setStakedInfoByMint] = useState<{
    [key: string]: any;
  }>({});
  const [loadingItems, setLoadingItems] = useState<LoadingItems>({});
  const { reFetch, stakingServiceInstance } = useWLProject();

  const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

  const manageError = function (err: any) {
    Sentry.captureException(err, {
      tags: stakingServiceInstance?.getDebugInfo(),
    });

    const message = parseSendTransactionError(err);

    toast.error("Error: " + message, {
      duration: 5000,
      position: "bottom-center",
    });
  };

  const setLoading = useCallback(
    (action: LoadingAction, mint: string, isLoading: boolean) => {
      setLoadingItems((prevState) => ({
        ...prevState,
        [mint]: { [action]: isLoading },
      }));
    },
    []
  );

  const fetchStakedInfo = useCallback(
    async (mint) => {
      try {
        const info = await stakingServiceInstance?.getStakedInfoForMint(mint);

        setStakedInfoByMint((stakedInfo) => ({ ...stakedInfo, [mint]: info }));
      } catch (err) {}
    },
    [stakingServiceInstance]
  );

  const stake = useCallback(
    async (mint: string) => {
      if (!stakingServiceInstance) {
        return;
      }
      const timeoutToast = new TimeoutToast();
      setLoading(LoadingAction.STAKE, mint, true);
      try {
        const sign = await stakingServiceInstance.stakeSend(mint);

        if (!sign) {
          return;
        }

        timeoutToast.prepare([sign]);

        await stakingServiceInstance.confirmTransaction(sign);

        toast.success("NFT Staked successfully!", {
          duration: 5000,
          position: "bottom-center",
        });

        sleep(1000);
        await reFetch();
      } catch (err: any) {
        Sentry.withScope((scope) => {
          scope.setExtra("mint", mint);
          Sentry.captureException(err);
        });
        manageError(err);
      } finally {
        timeoutToast.dismiss();
        setLoading(LoadingAction.STAKE, mint, false);
      }
    },
    [reFetch, stakingServiceInstance, setLoading]
  );

  const unstake = useCallback(
    async (mint: string, pendingRewards: number) => {
      if (!stakingServiceInstance) {
        return;
      }
      const timeoutToast = new TimeoutToast();
      setLoading(LoadingAction.UNSTAKE, mint, true);
      try {
        const sign = await stakingServiceInstance.unstakeSend(
          mint,
          pendingRewards
        );

        if (!sign) {
          return;
        }

        timeoutToast.prepare([sign]);

        await stakingServiceInstance.confirmTransaction(sign);

        toast.success("Claimed and Unstaked!", {
          duration: 5000,
          position: "bottom-center",
        });
        sleep(1000);
        await reFetch();
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setExtra("mint", mint);
          Sentry.captureException(err);
        });
        manageError(err);
      } finally {
        timeoutToast.dismiss();
        setLoading(LoadingAction.UNSTAKE, mint, false);
      }
    },
    [reFetch, stakingServiceInstance, setLoading]
  );

  const claim = useCallback(
    async (mint: string) => {
      if (!stakingServiceInstance) {
        return;
      }
      const timeoutToast = new TimeoutToast();
      setLoading(LoadingAction.CLAIM, mint, true);
      try {
        const signature = await stakingServiceInstance.claimSend(mint);
        if (!signature) {
          return;
        }
        timeoutToast.prepare([signature]);
        await stakingServiceInstance.confirmTransaction(signature);
        toast.success("Rewards claimed!", {
          duration: 5000,
          position: "bottom-center",
          icon: "💸",
        });
        await reFetch();
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setExtra("mint", mint);
          Sentry.captureException(err);
        });
        manageError(err);
      } finally {
        timeoutToast.dismiss();
        setLoading(LoadingAction.CLAIM, mint, false);
      }
    },
    [reFetch, stakingServiceInstance, setLoading]
  );

  return {
    stake,
    unstake,
    claim,
    loadingItems,
    fetchStakedInfo,
    stakedInfoByMint,
  };
};

export default useNFTCard;

import { ActiveLink } from "components/ActiveLink";
import React, { forwardRef, ReactNode } from "react";
import { motion } from "framer-motion";
import { useWLProject } from "modules/WLProject";

type Props = {
  submenu?: ReactNode;
};

// eslint-disable-next-line react/display-name
const MenuItem = forwardRef<
  HTMLAnchorElement,
  {
    number: number | undefined;
    name: string;
    showNumber?: boolean;
  } & React.HTMLProps<HTMLAnchorElement>
>(({ number, name, showNumber, ...props }, ref) => (
  <a ref={ref} {...props}>
    <div>{name}</div>
    {!!number && showNumber && (
      <motion.span
        className="badge badge-secondary ml-2"
        initial={false}
        animate={{ opacity: 1 }}
      >
        {number}
      </motion.span>
    )}
  </a>
));

const ProjectMenu = (props: Props) => {
  const { walletNFTs, stackedNFTs } = useWLProject();

  return (
    <div className="mt-4 w-full px-4">
      <div className="menu flex flex-col rounded-lg bg-neutral p-4 md:flex-row">
        <div className="mb-8 flex w-full flex-1 items-center md:mb-0 md:w-auto md:flex-none">
          <div className="mr-4 flex-1 md:min-w-[140px] md:flex-auto">
            <ActiveLink
              href={`/`}
              activeClassName="bg-primary text-neutral hover:bg-primary"
            >
              <MenuItem
                number={walletNFTs?.length}
                name="Wallet"
                showNumber={true}
                className="btn w-full text-center"
              />
            </ActiveLink>
          </div>
          <div className="flex-1 md:min-w-[140px] md:flex-auto">
            <ActiveLink
              href={`/staked`}
              activeClassName="bg-primary text-neutral hover:bg-primary"
            >
              <MenuItem
                number={stackedNFTs?.length}
                name="Staked"
                showNumber={true}
                className="btn w-full text-center"
              />
            </ActiveLink>
          </div>
        </div>
        {!!props.submenu && (
          <div className="w-full md:flex md:w-auto md:flex-1 md:justify-end">
            {props.submenu}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectMenu;

import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import useSWR from "swr";
import { fetcher } from "utils/fetcher";
import type { CardNFT } from "types";
import { LoadingAction } from "modules/useNFTCard";
import { useInView } from "react-intersection-observer";
import "react-lazy-load-image-component/src/effects/opacity.css";

// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";

function useWindowFocus() {
  const [focused, setFocus] = useState(true);
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  });

  return focused;
}

type Props = {
  nft: CardNFT;
  stake: Function;
  unstake: Function;
  claim: Function;
  getIsLoading: Function;
  stakedInfo: any;
  fetchStakedInfo: (mint: string) => void;
};

const noop = () => {};

const NFTCard: React.FC<Props> = ({
  nft,
  stake,
  unstake,
  claim,
  getIsLoading,
  stakedInfo,
  fetchStakedInfo,
}) => {
  const [cardRendered, setCardRendered] = useState(false);
  const [infoRef, infoInView] = useInView();
  const [cardRef, cardInView] = useInView();
  const isFocused = useWindowFocus();

  const uri = nft?.data?.uri;

  const handleStake = useCallback(() => {
    stake(nft?.mint);
  }, [stake, nft?.mint]);

  const handleUnstake = useCallback(() => {
    unstake(nft?.mint, pendingRewards);
  }, [unstake, nft?.mint, stakedInfo]);

  const handleClaim = useCallback(() => {
    claim(nft?.mint);
  }, [claim, nft?.mint]);

  const isStaking = getIsLoading(nft.mint, LoadingAction.STAKE);
  const isUnstaking = getIsLoading(nft.mint, LoadingAction.UNSTAKE);
  const isClaiming = getIsLoading(nft.mint, LoadingAction.CLAIM);

  const isOperating = isStaking || isUnstaking || isClaiming;

  const { data: uriMetadata } = useSWR(cardInView && uri, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const nftName = uriMetadata?.name;
  const image = uriMetadata?.image;

  const pendingRewards = stakedInfo?.pendingRewards;
  const lastClaim = stakedInfo?.lastClaim;
  const stakedAt = stakedInfo?.stakedAt;

  const shouldRenderFirstTime = infoInView && !cardRendered;

  useEffect(() => {
    if (!shouldRenderFirstTime) {
      return;
    }
    fetchStakedInfo(nft.mint);
    setCardRendered(true);
  }, [shouldRenderFirstTime, nft.mint, fetchStakedInfo]);

  const isVisible = infoInView && isFocused;

  useEffect(() => {
    // Periodic fetch
    const timer = setInterval(function () {
      if (!isVisible) {
        clearInterval(timer);
        return;
      }
      fetchStakedInfo(nft.mint);
    }, 10000);

    return () => clearInterval(timer);
  }, [isVisible, nft.mint, fetchStakedInfo]);

  return (
    <motion.div
      className="flex flex-col"
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        className="card card-compact rounded-lg bg-neutral shadow-xl"
        ref={cardRef}
      >
        <div className="relative">
          {!image && (
            <div className="relative !block overflow-hidden bg-base-300 pt-[95%]" />
          )}
          {!!image && (
            <LazyLoadImage
              src={image}
              alt={nftName}
              className="absolute top-0 left-0 right-0 object-fill"
              effect="opacity"
              wrapperClassName="relative pt-[95%] overflow-hidden !block bg-base-300"
            />
          )}
        </div>
        <div className="card-body text-white" ref={infoRef}>
          <div className="font-semibold">{nftName || "-"}</div>
          <div className="text-slate-500">
            <div>Rarity multiplier: {nft?.rarityMultiplier / 100}x</div>
            {!!nft?.staked && (
              <div>
                <div>
                  Pending rewards:{" "}
                  {pendingRewards ? pendingRewards.toString() : "-"}
                </div>
                <div>
                  Last claim:{" "}
                  {lastClaim > 0
                    ? new Date(lastClaim * 1000).toLocaleDateString()
                    : "-"}
                </div>
                <div>
                  Staked since:{" "}
                  {stakedAt > 0
                    ? new Date(stakedAt * 1000).toLocaleDateString()
                    : "-"}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex border-t border-t border-t-gray-800">
          {!nft?.staked && (
            <button
              className={cx(
                "btn btn-ghost flex-1 rounded-none hover:btn-primary",
                {
                  loading: isStaking,
                }
              )}
              onClick={isStaking ? noop : handleStake}
            >
              Stake
            </button>
          )}
          {nft?.staked && (
            <>
              <button
                className={cx(
                  "btn btn-ghost flex-1 rounded-none hover:btn-primary",
                  {
                    loading: isUnstaking,
                  }
                )}
                onClick={isUnstaking ? noop : handleUnstake}
                disabled={isOperating && !isUnstaking}
              >
                Unstake
              </button>
              <button
                className={cx(
                  "btn btn-ghost flex-1 rounded-none hover:btn-primary",
                  {
                    loading: isClaiming,
                  }
                )}
                onClick={isClaiming ? noop : handleClaim}
                disabled={isOperating && !isClaiming}
              >
                Claim
              </button>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default NFTCard;

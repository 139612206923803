import React from "react";
import { useWLProject } from "modules/WLProject";
import cx from "classnames";

const StakedSubmenu = () => {
  const { stackedNFTs, claimAll, claimingAll, unstakeAll, unstakingAll } =
    useWLProject();
  return (
    <div className="flex w-full flex-1 items-center md:mb-0 md:w-auto md:flex-none">
      <button
        className={cx(
          "btn btn-primary mr-4 flex-1 md:min-w-[120px] md:flex-auto",
          {
            loading: unstakingAll,
          }
        )}
        disabled={claimingAll || unstakingAll || !stackedNFTs?.length}
        onClick={unstakeAll}
      >
        Unstake all
      </button>
      <button
        className={cx("btn btn-primary flex-1 md:min-w-[120px] md:flex-auto", {
          loading: claimingAll,
        })}
        disabled={claimingAll || unstakingAll || !stackedNFTs?.length}
        onClick={claimAll}
      >
        Claim all
      </button>
    </div>
  );
};

export default StakedSubmenu;

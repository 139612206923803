import fidelion_bg from "../assets/fidelion_bg.png";

const GOOD_MOOD_DUDES = `
@import url('https://fonts.cdnfonts.com/css/gotham-black');

:root {
  background-color: white;
  font-family: 'Gotham Black', sans-serif;
}

:root {
  color: black;
}

.rounded-lg {
  border-radius: 0;
}

.text-slate-500 {
  font-size: 0.775rem;
}

.stat-title, .stat-desc {
  opacity: 1;
}

.shadow-lg, .shadow-l {
  --tw-shadow: 0;
  --tw-shadow-colored: 0;
  box-shadow: 0;
}

.bg-neutral {
  background-color: #F6EB44;
}

.bg-primary {
  background-color: black;
}

.bg-primary:hover {
  background-color: black;
  opacity: 0.9;
}

.btn {
  border: none;
  border-radius: 0;
}

.btn-primary {
  background-color: black;          
  color: white;
}

.btn:hover, .btn-active {
  color: #F6EB44;
}

.btn-secondary {
  background-color: black;          
  color: white;
}

.text-secondary {
  --tw-text-opacity: 1;
  color: black;
}

.fill-secondary {
  fill: black;
}

.card-body, .card-body.btn {
  background-color: black;
  color: white;
}

button.btn.btn-ghost{
  background-color: black;
  color: white;
}

.wallet-adapter-button {
  color: black;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: transparent;
}

.btn-disabled, .btn[disabled] {
  color: white;
}

.btn-primary:hover{
  background-color: black;
  opacity: 0.9;
}

.btn-secondary {
  background-color: black;
}

.text-slate-500 {
  color: white;
}

.hover\:btn-primary:hover {
  color: white;
}

.btn-secondary:hover {
  background-color: black;
  opacity: 0.9;
}

.no-items {
  color: black;
}

a.btn {
  color: white;
}

a.btn.bg-primary {
  color: white;
}

a.btn.bg-primary:hover {
  color: #F6EB44;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  color: white;
}

button.btn.btn-ghost:hover {
  background-color: transparent;
  color: black;
}
`;

const FIDELION = `

:root {
  background: url(${fidelion_bg.src}) no-repeat center center fixed; ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
  background-color: white;
}

.navbar {
  background-image: url("https://raw.githubusercontent.com/floppy-labs/public-images/master/fidelion.png");
  background-repeat: no-repeat;
  background-size: 40px;
  background-position-y: center;
  border-radius: 0px;
}

.brand-button {
  visibility: hidden;
}

.navbar-container {
  position: absolute;
  left: 0px;
  width: 100%;
}

.avatar {
  display:none;
}

.main-container {
  max-width: 1400px;
  margin: 0 auto;
  position: unset;
}

.info-boxes {
  margin-top: 100px;
}

.btn, .wallet-adapter-button {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid grey;
  background-color: #00000022;
  color: white;
}

.bg-primary:hover {
  border-radius: 4px;
  border-color: grey;
  background-color: #444450;
  opacity: 0.9;
}

.btn-primary:hover{
  border-radius: 4px;
  border-color: grey;
  background-color: #444450;
  opacity: 0.9;
}

.hover\:btn-primary:hover {
  color: white;
}

a.btn.bg-primary {
  color: white;
}

.btn-secondary:hover {
  border-radius: 4px;
  border-color: grey;
  background-color: #444450;
  opacity: 0.9;
}

.btn-ghost:hover, .btn:hover, .card .btn:hover {
  border-radius: 4px;
  border-color: grey;
  background-color: #444450;
  opacity: 0.9;
  border-width: 1px;
  color: white;
}

.mask-hexagon {
  -webkit-mask-image: none;
  mask-image: none;
}

.card-body, .card-body.btn {
  color: white;
}

.text-slate-500 {
  color: white;
}

.card .btn {
  margin: 10px;
}

.rounded-lg {
  border-radius: 6px;
}

.border-t {
  border-top-width: 0px;
}

.menu {
  background: transparent;
  padding: 40px 0px 15px;
}

.navbar {
  background-color: transparent;
  padding: 0;
}

`;

const SECOND_CHANCE = `
@import url('https://fonts.cdnfonts.com/css/gotham-black');

:root {
  background: url('https://raw.githubusercontent.com/FloppyLabsOrg/public-images/master/secondchancebg.jpeg') no-repeat center center fixed; ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
  background-color: white;
}

.navbar {
  background-image: url("https://raw.githubusercontent.com/floppy-labs/public-images/master/2nd_chance.jpeg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position-y: center;    
}

.navbar-container.p-4 {
    padding-bottom: 0px;
    padding-top: 0px;
}

button.wallet-adapter-button.bold {
    padding: 0;
}

.shadow-l.navbar.rounded-lg.bg-neutral {
    background-color: transparent;
}

.brand-button {
  visibility: hidden;
}

:root {
  color: black;
}

.rounded-lg {
  border-radius: 0;
}

.text-slate-500 {
  font-size: 0.775rem;
}

.stat-title, .stat-desc {
  opacity: 1;
}

.shadow-lg, .shadow-l {
  --tw-shadow: 0;
  --tw-shadow-colored: 0;
  box-shadow: 0;
}

.bg-neutral {
  background-color: #19e5a0;
}

.bg-primary {
  background-color: black;
}

.bg-primary:hover {
  background-color: black;
  opacity: 0.9;
}

.btn {
  border: none;
  border-radius: 0;
}

.btn-primary {
  background-color: black;          
  color: white;
}

.btn:hover, .btn-active {
  color: #19e5a0;
}

.btn-secondary {
  background-color: black;          
  color: white;
}

.text-secondary {
  --tw-text-opacity: 1;
  color: black;
}

.fill-secondary {
  fill: black;
}

.card-body, .card-body.btn {
  background-color: black;
  color: white;
}

button.btn.btn-ghost{
  background-color: black;
  color: white;
}

.wallet-adapter-button {
  color: black;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: transparent;
}

.btn-disabled, .btn[disabled] {
  color: white;
}

.btn-primary:hover{
  background-color: black;
  opacity: 0.9;
}

.btn-secondary {
  background-color: black;
}

.text-slate-500 {
  color: white;
}

.hover\:btn-primary:hover {
  color: white;
}

.btn-secondary:hover {
  background-color: black;
  opacity: 0.9;
}

.no-items {
  color: black;
}

a.btn {
  color: white;
}

a.btn.bg-primary {
  color: white;
}

a.btn.bg-primary:hover {
  color: #F6EB44;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  color: white;
}

button.btn.btn-ghost:hover {
  background-color: transparent;
  color: black;
}
`;

type StringToString = {
  [key: string]: string;
};

// Map of project slug and custom style
const customStyles: StringToString = {
  "good-mood-dudes": GOOD_MOOD_DUDES,
  fidelion: FIDELION,
  "2ndchance": SECOND_CHANCE,
};

const getCustomStyle = (slug: string): string => {
  return customStyles[slug] || "";
};

export { getCustomStyle };

import React from "react";
import { useWLProject } from "modules/WLProject";
import cx from "classnames";

const WalletSubmenu = () => {
  const { walletNFTs, stakeAll, stakingAll } = useWLProject();
  return (
    <button
      className={cx("btn btn-primary w-full md:w-auto", {
        loading: stakingAll,
      })}
      disabled={stakingAll || !walletNFTs?.length}
      onClick={stakeAll}
    >
      Stake all
    </button>
  );
};

export default WalletSubmenu;

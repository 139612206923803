import React, { FC, Children, useState, useEffect, ReactNode } from "react";
import { useRouter } from "next/router";
import Link, { LinkProps } from "next/link";

interface Props extends LinkProps {
  activeClassName: string;
  children: ReactNode;
}

const ActiveLink: FC<Props> = ({
  children,
  activeClassName,
  ...props
}: Props) => {
  const { asPath, isReady } = useRouter();

  const child = Children.only(children) as any;
  const childClassName = child.props.className || "";
  const [className, setClassName] = useState(childClassName);

  const url = (props.as || props.href) as string;

  useEffect(() => {
    if (isReady) {
      const linkPathname = new URL(url, location.href).pathname;

      const activePathname = new URL(asPath, location.href).pathname;

      const newClassName =
        linkPathname === activePathname
          ? `${childClassName} ${activeClassName}`.trim()
          : childClassName;

      if (newClassName !== className) {
        setClassName(newClassName);
      }
    }
  }, [
    asPath,
    isReady,
    url,
    childClassName,
    activeClassName,
    setClassName,
    className,
  ]);

  return (
    <Link {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  );
};

export default ActiveLink;
